import Dashboard from "./dashboard/index";
import Layout from "../../shared/layout";
import { Route } from "react-router-dom";
import Users from "./users";
import NFT from "./nft";
import OneUser from "./users/user";
import OneNFT from "./nft/nft";
import CreateNFT from "./nft/nft/create";
import Request from "./users/artistReqest";

import News from "./news";
import CreatePost from "./news/createPost";
import CreatePage from "./pages/createPage";
import Tags from "./tags/tags";
import Pages from "./pages";
import EditPost from "./news/editPost";
import EditPage from "./pages/editPage";
import EditPages from "./pages/editPages";
import Categories from "./categories/categories";
import ProfileChapters from "./chapters/index";
import SearchSettings from "./search/index";
import ReviewPage from "./pages/review";

const Admin = ({ match }: any) => {
  return (
    <Layout>
      <Route exact path={`${match.path}/users`} component={Users} />
      <Route exact path={`${match.path}/users/:id`} component={OneUser} />
      <Route exact path={`${match.path}/nft`} component={NFT} />
      <Route exact path={`${match.path}/createNFT`} component={CreateNFT} />
      <Route exact path={`${match.path}/nft/:id`} component={OneNFT} />
      <Route exact path={`${match.path}/request`} component={Request} />
      <Route exact path={`${match.path}/news`} component={News} />
      <Route exact path={`${match.path}/news/:id`} component={EditPost} />
      <Route exact path={`${match.path}/createPost`} component={CreatePost} />
      <Route exact path={`${match.path}/tags`} component={Tags} />
      <Route exact path={`${match.path}/categories`} component={Categories} />
      <Route exact path={`${match.path}/pages`} component={Pages} />
      <Route exact path={`${match.path}/createPage`} component={CreatePage} />
      <Route exact path={`${match.path}/pages/:id`} component={EditPage} />
      <Route exact path={`${match.path}/editPages`} component={EditPages} />
      <Route
        exact
        path={`${match.path}/chapters`}
        component={ProfileChapters}
      />
      <Route exact path={`${match.path}/search`} component={SearchSettings} />
      <Route exact path={`${match.path}/reviews`} component={ReviewPage} />
    </Layout>
  );
};

export default Admin;
