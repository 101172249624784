import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { UserStore } from "../../../stores/user/UserStore";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import InputForm from "./../news/form/inputForm";
import { ICategory, IInputs } from "./types";
import Table, { ITableRow } from "./../../../components/table/table";
import { toast } from "react-toastify";

const getContentNews = (categories: ICategory) => {
  const index = categories?.translations
    ?.map((item) => item.lang)
    .indexOf("ru");
  return categories?.translations[index];
};

const createData = (category): ITableRow => {
  const translations = getContentNews(category);

  return {
    id: category._id,
    name: translations.name,
  };
};

const CATEGOORIES_TABLE_HEAD_OPTIONS = [
  { id: "name", label: "Название" },
  { id: "deleted", label: "Удалить" },
];

const Categories = observer(() => {
  const store = useInjection(UserStore);

  const [newCategory, setNewCategory] = useState<IInputs>({ ru: "", en: "" });
  const [rows, setRows] = useState<ITableRow[]>([]);

  useEffect(() => {
    store.getAllCategories();
  }, []);

  useEffect(() => {
    store.categories &&
      setRows(store.categories?.map((category) => createData(category)));
  }, [store.categories]);

  const submit = (e) => {
    e.preventDefault();
    const formData = {
      translations: [
        { lang: "ru", name: newCategory.ru },
        { lang: "en", name: newCategory.en },
      ],
    };
    store.addNewCategory(formData).then(() => {
      setNewCategory({ ru: "", en: "" });
    });
  };

  const handleRemove = (id: string) => {
    store.removCategory(id).then(() => {
      toast.success("Категория удалён");
    });
  };

  return (
    <div>
      <h5>Список тегов</h5>
      <form onSubmit={submit}>
        <div style={{ width: 500 }}>
          <InputForm
            name="category-ru"
            title="Название категории (RU)"
            inputValue={newCategory.ru}
            required={true}
            handleChange={(e) =>
              setNewCategory((prev) => ({ ...prev, ru: e.target.value }))
            }
          />
          <InputForm
            name="category-en"
            title="Название категории (EN)"
            inputValue={newCategory.en}
            required={true}
            handleChange={(e) =>
              setNewCategory((prev) => ({ ...prev, en: e.target.value }))
            }
          />
        </div>

        <Button type="submit">Создать категорию</Button>
      </form>

      <br />
      <br />

      <Table
        headCells={CATEGOORIES_TABLE_HEAD_OPTIONS}
        rows={rows}
        handleRemove={handleRemove}
      />
    </div>
  );
});

export default Categories;
