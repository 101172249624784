import { useInjection } from "inversify-react"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"
import { UserStore } from "../../../stores/user/UserStore"
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import CreateUser from "./createUserModal";

const Flex = styled.div`
  width:  90vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  
`



const Users = observer(() => {

    const store = useInjection(UserStore)
    const [userId, setUserID] = useState(null)
    const [newUserModal, setNewUserModal] = useState(false)
    const history = useHistory();
    
    useEffect(() => {
        store.GetUsers();

        return () => setUserID(null);
    }, [])

    const onChange = (e) => {
        switch(e.target.value){
            case 'onOpen': return onOpen();
            case 'onDelete': return onDelete();
            default: ''
        }
    }

    const onOpen = () => {
            history.push(`/admin/users/${userId}`)
    }

    const onDelete = () => {
        if (window.confirm(`Вы уверены что хотите удалить пользователя ?`)) {
            store.deleteUser(userId)
        }

        // store.deleteUser(userId)
    }

    const onCreate = () => {
        //modal here
    }


    return (
        <div>
            <Flex>
                <h5>Список зарегистрированых пользователей</h5>
                <Button onClick={()=>setNewUserModal(true)}>Создать пользователя</Button>
            </Flex>
            <br />
            <br />

<table className="table">
  <thead>
    <tr>
      <th scope="col">@id</th>
      <th scope="col">ФИО</th>
      <th scope="col">email</th>
      <th scope="col">Тип</th>
      <th scope="col">Баланс</th>

      <th scope="col">Опции</th>

    </tr>
  </thead>
  <tbody>
      {store?.users ? store.users.map(user => {
          return (
            <tr 
                onMouseOver={() =>setUserID(user._id)}
                onMouseEnter={() => setUserID(user._id)}
                style={{backgroundColor: userId==user._id && '#ebebeb'}}
                onDoubleClick={() => userId==user._id && history.push(`/admin/users/${userId}`)} >
            <th scope="row">{user.user_id}</th>
            <td>{user.fullname}</td>
            <td>{user.email}</td>
            <td>{user.user_type}</td>
            <td>{user.balance} BNB</td>

            <td>
                <select onChange={onChange}>
                    <option>Опции</option>
                    <option value='onOpen'>Подробнее</option>
                    <option value='onOpen'>Редактировать</option>
                    <option value='onDelete'>Удалить</option>

                </select>
            </td>
          </tr>
          )
      }) : (
          <p>loading...</p>
      )}
    
    </tbody>
  </table>
    
    {newUserModal && <CreateUser close={()=>setNewUserModal(false)} />}

</div>
    )
})

export default Users