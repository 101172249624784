import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UserStore } from "../../../stores/user/UserStore";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { IPage, IPageTranslation } from "./types";
import Table, { ITableRow } from "./../../../components/table/table";

const getContentPages = (page: IPage): IPageTranslation => {
  const index = page?.translations?.map((item) => item.lang).indexOf("ru");
  return page?.translations[index];
};

const createData = (page: IPage): ITableRow => {
  const translations = getContentPages(page);

  return {
    id: page._id,
    name: translations.title,
    title: translations.h1,
  };
};

const PAGES_TABLE_HEAD_OPTIONS = [
  { id: "name", label: "Название" },
  { id: "title", label: "Заголовок" },
  { id: "deleted", label: "Удалить" },
];

const Pages = observer(() => {
  const history = useHistory();
  const store = useInjection(UserStore);
  const [rows, setRows] = useState<ITableRow[]>([]);

  useEffect(() => {
    store.GetAllPages();
  }, []);

  useEffect(() => {
    store.allPages && setRows(store.allPages?.map((page) => createData(page)));
  }, [store?.allPages]);

  const handleRemove = (url: string): void => {
    store.DeletePage(url).then(() => {
      toast.success("Страница удалена");
    });
  };

  const handleDoubleClick = (url) => {
    history.push(`/admin/pages/${url}`);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <h5>Список всех страниц</h5>
        <div>
          <Button
            style={{ marginRight: "15px" }}
            onClick={() => history.push(`/admin/editPages`)}
          >
            Редактировать страницы
          </Button>
          <Button onClick={() => history.push(`/admin/createPage`)}>
            Добавить страницу
          </Button>
        </div>
      </div>

      <Table
        headCells={PAGES_TABLE_HEAD_OPTIONS}
        rows={rows}
        handleRemove={handleRemove}
        handleDoubleClick={handleDoubleClick}
      />
    </div>
  );
});

export default Pages;
