import React from "react";
import { IInput } from "../types";

const inputForm: React.FC<IInput> = ({
  handleChange,
  inputValue,
  title,
  name,
  required = false,
}) => {
  return (
    <div className="input-group my-3">
      <span className="input-group-text w-17" id={name}>
        {title}
      </span>
      <input
        required={required}
        type="text"
        value={inputValue}
        className="form-control"
        onChange={handleChange}
        name={name}
        aria-describedby="basic-addon3"
      />
    </div>
  );
};

export default inputForm;
