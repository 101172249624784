import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { UserStore } from "../../../stores/user/UserStore";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";

const TR = styled.tr`
  &:hover {
    background-color: #e4e4e4;
  }
`;

const Tags = observer(() => {
  const store = useInjection(UserStore);
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    store.getAllTags();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    store.addNewTag(newTag);
  };

  return (
    <div>
      <h5>Список тегов</h5>
      <form onSubmit={submit}>
        <input
          type="text"
          placeholder="Введите название тега"
          onChange={(e) => setNewTag(e.target.value)}
        />
        <Button type="submit">Создать тег</Button>
      </form>

      <br />
      <br />

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Название</th>
            <th scope="col">Удалить</th>
          </tr>
        </thead>
        <tbody>
          {store?.tags ? (
            store.tags?.map((el, i) => {
              return (
                <TR key={"tags" + i}>
                  <td scope="row">{el.tag}</td>
                  <td>
                    <div
                      style={{ cursor: "pointer", width: "40px" }}
                      onClick={() => store.removeTag(el.tag)}
                    >
                      x
                    </div>
                  </td>
                </TR>
              );
            })
          ) : (
            <p>loading...</p>
          )}
        </tbody>
      </table>
    </div>
  );
});

export default Tags;
