import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const Path = process.env.REACT_APP_IP;
const Container = styled.div`
  & .flex {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
  }
  & img {
    border-radius: 10px;
    margin-bottom: 20px;
  }
  & button {
    display: block;
  }
`;

const OneNFT = observer(() => {
  const params: any = useParams();
  const store = useInjection(UserStore);
  const [isEdit, setIsEdit] = useState(true);

  const [formData, setFormData] = useState(null);
  let id = params.id;

  useEffect(() => {
    store.GetNFT(id);
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "form data");
    store.ChangeUser(formData, id).then((res) => {
      toast.success("Изменение данных");
    });
    setIsEdit(true);
  };

  const onDelete = () => {
    // store.deleteUser(id)
  };

  return (
    <Container>
      <div className="flex">
        <div>
          <img src={Path + "/" + store.nft?.file} />
          <Button>Удалить</Button>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <h1>{store.nft?.title}</h1>
          <span>{store.nft?.category}</span>
          <p>
            {store.nft?._id} / {store.nft?.new_id}
          </p>
          <div>Автор</div>
          <h3>{store.nft?.author?.fullname}</h3>
          <div>Соавторы</div>
          <h3>{store.nft?.coauthor}</h3>
          <div>Цена</div>
          <h3>{store.nft?.price} BNB</h3>
          <div>Описание</div>
          <div>{store.nft?.about}</div>
        </div>
      </div>
    </Container>
  );
});

export default OneNFT;
