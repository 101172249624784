import { useEffect, useState } from "react";
import { languages } from "../../../../utils/constants";
import styled from "styled-components";
import { SubmitButton } from "../../users/createUserModal";
import InputForm from "./inputForm";
import { IForm, IPage } from "../types";

interface IFooterCol {
  value: number;
  title: string;
}

const footerColOptions: Array<IFooterCol> = [
  { value: 1, title: "Колонка 1" },
  { value: 2, title: "Колонка 2" },
  { value: 3, title: "Колонка 3" },
];

const getDefaultInitialValues = (isCreatePage: boolean = false): IPage => {
  const otherProps = isCreatePage
    ? { isVisible: false, footerColIndex: 0, isExternal: false }
    : {};

  let result = {
    translations: [],
    url: "",
    ...otherProps,
  };

  languages.forEach((language) => {
    result.translations.push({
      lang: language,
      title: "",
      h1: "",
      metaTite: "",
      metaDescription: "",
    });
  });

  return result;
};

export const getLanguageIndex = (
  values: IPage,
  lang: string = "ru"
): number => {
  return values.translations?.map((item) => item.lang).indexOf(lang);
};

const getInputValue = (
  totalInfo: IPage,
  keyName: string,
  lang: string = "ru"
): string => {
  const index = getLanguageIndex(totalInfo, lang);
  return totalInfo.translations[index][keyName];
};

const FormCreateUpdate: React.FC<IForm> = ({
  handleSubmit,
  isCreatPage = false,
  initialValues = getDefaultInitialValues(isCreatPage),
  isCreate = true,
}) => {
  const [postData, setPostData] = useState<IPage>(initialValues);

  const [isVisibleInFooter, setIsVisibleInFooter] = useState<boolean>(
    Boolean(postData.footerColIndex)
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [inputName, lang]: string[] = e.target.name.split("-");
    const newPostData = { ...postData };
    const index = getLanguageIndex(newPostData, lang);
    newPostData.translations[index][inputName] = e.target.value;

    setPostData(newPostData);
  };

  const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPostData = { ...postData };
    setPostData({ ...newPostData, [e.target.name]: e.target.checked });
  };

  const handleFooterCol = (value: number) => {
    const newPostData = { ...postData };
    setPostData({ ...newPostData, footerColIndex: value });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(postData);
  };

  useEffect(() => {
    const newPostData = { ...postData };
    if (isVisibleInFooter) {
      if (!newPostData.footerColIndex) {
        setPostData({ ...newPostData, footerColIndex: 1 });
      }
    } else {
      setPostData({ ...newPostData, footerColIndex: 0 });
    }
  }, [isVisibleInFooter]);

  return (
    <form onSubmit={onSubmit}>
      <FormWrapper>
        {postData.translations.map((item) => {
          return (
            <FormItemWrapper>
              <InputForm
                title={`Название в навигации (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(postData, "title", item.lang)}
                name={`title-${item.lang}`}
                required={true}
              />

              <InputForm
                title={`Заголовок (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(postData, "h1", item.lang)}
                name={`h1-${item.lang}`}
                required={!postData.isExternal}
              />

              <InputForm
                title={`Meta title (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(postData, "metaTite", item.lang)}
                name={`metaTite-${item.lang}`}
                required={!postData.isExternal}
              />

              <InputForm
                title={`Meta description (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(
                  postData,
                  "metaDescription",
                  item.lang
                )}
                name={`metaDescription-${item.lang}`}
                required={!postData.isExternal}
              />
            </FormItemWrapper>
          );
        })}
      </FormWrapper>

      <InputForm
        title={`URL (латиница в нижнем регистре или знак "-")`}
        handleChange={(e) => setPostData({ ...postData, url: e.target.value })}
        inputValue={postData.url}
        name={`url`}
        required={true}
        pattern={postData.isExternal ? "" : "[a-z-]{3,}"}
      />

      <VisibleWrapper>
        <VisibleInputWrapper>
          <VisibleTitle>Внешняя ссылка?</VisibleTitle>
          <input
            type="checkbox"
            name="isExternal"
            checked={postData.isExternal}
            onChange={inputHandleChange}
          />
        </VisibleInputWrapper>

        <VisibleInputWrapper>
          <VisibleTitle>Отображать в шапке</VisibleTitle>
          <input
            type="checkbox"
            name="isVisible"
            checked={postData.isVisible}
            onChange={inputHandleChange}
          />
        </VisibleInputWrapper>

        <VisibleInputWrapper>
          <VisibleTitle>Отображать в footer</VisibleTitle>
          <input
            type="checkbox"
            name="isVisibleInFooter"
            checked={isVisibleInFooter}
            onChange={() => setIsVisibleInFooter(!isVisibleInFooter)}
          />
        </VisibleInputWrapper>

        {isVisibleInFooter ? (
          <FooterColsInputs>
            {footerColOptions.map((item) => (
              <VisibleInputWrapper>
                <VisibleTitle>{item.title}</VisibleTitle>
                <input
                  type="checkbox"
                  name="footerColIndex"
                  checked={postData.footerColIndex === item.value}
                  onChange={() => handleFooterCol(item.value)}
                />
              </VisibleInputWrapper>
            ))}
          </FooterColsInputs>
        ) : null}
      </VisibleWrapper>

      <div style={{ width: "50%" }}>
        <SubmitButton type="submit" className="frm_button">
          {`${isCreate ? "Создать" : "Редактировать"} страницу`}
        </SubmitButton>
      </div>
    </form>
  );
};

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FormItemWrapper = styled.div`
  width: 49%;
`;

const VisibleWrapper = styled.div`
  display: flex;
`;

const VisibleInputWrapper = styled.div`
  display: flex;
  // align-items: center;
  padding: 0 10px;
  border-right: 1px solid black;
`;
const VisibleTitle = styled.p`
  font-size: 20px;
  margin-right: 10px;
  margin-bottom: 0;
`;
const FooterColsInputs = styled.div`
  margin-left: auto;
  display: flex;
`;

export default FormCreateUpdate;
