import { useEffect, useRef } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import Editor from "./../../../components/editor/editor";

import FormCreateUpdate, { getLanguageIndex } from "./../pages/form/form";
import styled from "styled-components";
import { routes } from "../../../utils/constants";
import { getValidationURLS } from "../../../utils/getValidationURLS";
import { IContentRef, IPage } from "./types";

const createPage = observer(() => {
  const contentRuRef = useRef<IContentRef | null>(null);
  const contentEnRef = useRef<IContentRef | null>(null);
  const history = useHistory();
  const userStore = useInjection(UserStore);

  const getAllPages = (): void => {
    !userStore.allPages && userStore.GetAllPages();
  };

  const validationURLs = getValidationURLS(userStore.allPages);

  const handleSubmit = (
    textRu: IContentRef | string,
    textEn: IContentRef | string,
    postData: IPage
  ) => {
    const isUrlValidate = validationURLs.includes(postData.url);
    const pagePosition =
      userStore.allPages && userStore.allPages.length > 0
        ? Math.max(
            ...userStore.allPages?.map((item: IPage) => +item.position)
          ) + 1
        : 1;

    if (isUrlValidate) {
      toast.error("Указанный url уже существует");
      return;
    }

    const ruIndex = getLanguageIndex(postData, "ru");
    const enIndex = getLanguageIndex(postData, "en");

    postData.position = pagePosition;
    postData.translations[ruIndex].content = textRu;
    postData.translations[enIndex].content = textEn;

    userStore
      .AddPage(postData)
      .then(() => {
        toast.success("Страница добавлена");
        history.goBack();
      })
      .catch((err) => {
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };

  useEffect(() => {
    getAllPages();
  }, []);

  return (
    <>
      <Title className="lk-title">Создание страницы</Title>

      <EditorWrapper>
        <EditorWrapperItem>
          <SubTitle className="lk-title">(Русская версия)</SubTitle>
          <Editor forwardedRef={contentRuRef} />
        </EditorWrapperItem>

        <EditorWrapperItem>
          <SubTitle className="lk-title">(English version)</SubTitle>
          <Editor forwardedRef={contentEnRef} />
        </EditorWrapperItem>
      </EditorWrapper>

      <FormCreateUpdate
        handleSubmit={(postData) =>
          handleSubmit(contentRuRef.current, contentEnRef.current, postData)
        }
        isCreatPage={true}
      />
    </>
  );
});

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;
export const EditorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EditorWrapperItem = styled.div`
  width: 49%;
`;
export const SubTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

export default createPage;
