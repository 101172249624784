import { Container } from "inversify";
import { UserStore } from "./user/UserStore";
import { wrapHistory } from "oaf-react-router";
import {createBrowserHistory, History} from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";

// @ts-ignore
export class HistoryStore implements History {}

export class RootStore {
    public routerStore: RouterStore;
    public historyStore: HistoryStore;
    public userStore: UserStore;
    public container: Container;

    public constructor() {
        const browserHistory = createBrowserHistory();
        wrapHistory(browserHistory, {
            smoothScroll: true,
            primaryFocusTarget: 'body',
        });
        this.routerStore = new RouterStore();
        this.historyStore = syncHistoryWithStore(browserHistory, this.routerStore);

        this.userStore = new UserStore(this);

        this.container = new Container();
        this.container.bind(RouterStore).toConstantValue(this.routerStore);
        this.container.bind(HistoryStore).toConstantValue(this.historyStore);
        this.container.bind(UserStore).toConstantValue(this.userStore);

    }
}    

