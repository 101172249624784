import axios from "axios";

export const url = process.env.REACT_APP_IP;

console.log(url, "ggg");

export const setAuthToken = (token: string | null) => {
  if (token) {
    innerBackend.defaults.headers.common["gallery-admin-token"] = token;
  }
};

export const innerBackend = axios.create({
  baseURL: url,
  headers: {
    accept: "application/json",
  },
});
