import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UserStore } from "../../../stores/user/UserStore";
import { useHistory } from "react-router";
import styled from "styled-components";
import { toast } from "react-toastify";
import "./gridLoyautStyles.css";
import GridLayout from "react-grid-layout";
import { ILayout, IPage, IPageRowParams } from "./types";

const headLabels = ["Название", "Отображение"];

const editPages = observer(() => {
  const history = useHistory();
  const store = useInjection(UserStore);
  const [pages, setPages] = useState<IPage[] | null>(null);
  const [layoutValue, setLayoutValue] = useState<ILayout[] | null>(null);

  const getAllPages = (): void => {
    store.GetAllPages();
  };

  const handleSubmit = (): void => {
    const postData = {
      pages: [],
    };

    pages.forEach((page) => {
      const orderIndex = layoutValue?.map((item) => item.i).indexOf(page?.url);
      postData.pages.push({
        position: layoutValue[orderIndex].y,
        isVisible: page.isVisible,
        translations: page.translations?.map((translation) => {
          delete translation?._id;
          return JSON.parse(JSON.stringify(translation));
        }),
        url: page.url,
      });
    });

    store
      .EditPages(postData)
      .then(() => {
        toast.success("Страницы отредактированы");
        getAllPages();
        history.push("/admin/pages");
      })
      .catch((err) => {
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };

  useEffect(() => {
    getAllPages();
  }, []);

  useEffect(() => {
    store?.allPages && setPages(store?.allPages);
  }, [store?.allPages]);

  const getLayout = (values: IPage[]): ILayout[] => {
    return (
      (values?.length &&
        values?.map((item) => {
          return {
            i: item.url,
            w: 1,
            h: 1,
            x: 0,
            y: 0,
          };
        })) ||
      []
    );
  };

  const handleChange = (url: string): void => {
    const index = pages.map((item) => item.url).indexOf(url);
    let newPagesValue = [...pages];
    newPagesValue[index].isVisible = !newPagesValue[index].isVisible;
    setPages(newPagesValue);
  };

  if (!pages) {
    return <div>"Loading..."</div>;
  }

  return (
    <>
      <ControlsWrapper>
        <h5>Список всех страниц</h5>
        <Button onClick={handleSubmit}>Сохранить изминения</Button>
      </ControlsWrapper>
      <ContentWrapper>
        <HeadWrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
            margin: "0 10px",
            borderBottom: "1px solid #000",
            fontWeight: "bold",
          }}
        >
          {headLabels.map((item) => {
            return <div>{item}</div>;
          })}
        </HeadWrapper>

        <GridLayout
          container
          layout={getLayout(pages)}
          width={700}
          cols={1}
          spacing={0}
          draggableHandle=".drag"
          autoSize={true}
          rowHeight={100}
          className="layout"
          onLayoutChange={(layout: ILayout[]) => {
            setLayoutValue(layout);
          }}
        >
          {pages?.map((item) => (
            <div key={item.url}>
              <PageRow handleChange={handleChange} item={item} />
            </div>
          ))}
        </GridLayout>
      </ContentWrapper>
    </>
  );
});

const PageRow: React.FC<IPageRowParams> = ({ item, handleChange }) => {
  const indexOfRuLang = item?.translations
    ?.map((item) => item.lang)
    .indexOf("ru");

  return (
    <PageRowWrapper className="drag">
      <PageRowWrapperTitle>
        {item.translations[indexOfRuLang].title}
      </PageRowWrapperTitle>
      <PageRowWrapperInput
        type="checkbox"
        height={20}
        width={20}
        onChange={() => {
          handleChange(item.url);
        }}
        checked={item.isVisible}
      />
    </PageRowWrapper>
  );
};

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const ContentWrapper = styled.div`
  width: 700px;
`;

const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin: 0 10px;
  border-bottom: 1px solid #000;
  font-weight: bold;
`;

const PageRowWrapper = styled.div`
  display: flex;
  align-items: center;
  border: thick double #2c2e6550;
  padding: 20px;
  height: 100px;
  cursor: move;
`;

const PageRowWrapperTitle = styled.div`
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
`;

const PageRowWrapperInput = styled.input``;

export default editPages;
