export const languages = ["ru", "en"];
export const newsFormField = [
  "title",
  "description",
  "metaTite",
  "metaDescription",
];
export const routes = [
  {
    path: "users",
  },
  {
    path: "users",
  },
  {
    path: "nft",
  },
  {
    path: "createNFT",
  },
  {
    path: "request",
  },
  {
    path: "news",
  },
  {
    path: "createPost",
  },
  {
    path: "tags",
  },
  {
    path: "pages",
  },
  {
    path: "createPage",
  },
];
