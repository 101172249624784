import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";
import { INews, INewsTranslation } from "./types";
import Table, { ITableRow } from "./../../../components/table/table";

const getContentNews = (news: INews): INewsTranslation => {
  const index = news?.translations?.map((item) => item.lang).indexOf("ru");
  return news?.translations[index];
};

const createData = (news: INews): ITableRow => {
  const translations = getContentNews(news);

  return {
    id: news._id,
    title: translations.title,
    description: translations.description,
    image: news.file,
  };
};

const NEWS_TABLE_HEAD_OPTIONS = [
  { id: "title", label: "Заголовок" },
  { id: "description", label: "Краткое описание" },
  { id: "image", label: "Превью" },
  { id: "deleted", label: "Удалить" },
];

const News = observer(() => {
  const history = useHistory();
  const store = useInjection(UserStore);
  const [rows, setRows] = useState<ITableRow[]>([]);

  useEffect(() => {
    store.GetAllNews();
  }, []);

  useEffect(() => {
    store?.allNews && setRows(store?.allNews?.map((news) => createData(news)));
  }, [store?.allNews]);

  const handleRemove = (id: string): void => {
    store.DeletePost(id).then(() => {
      toast.success("Новость удалена");
    });
  };

  const handleDoubleClick = (id) => {
    history.push(`/admin/news/${id}`);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <h5>Список всех новостей</h5>
        <Button onClick={() => history.push(`/admin/createPost`)}>
          Добавить новость
        </Button>
      </div>

      <Table
        headCells={NEWS_TABLE_HEAD_OPTIONS}
        rows={rows}
        handleRemove={handleRemove}
        handleDoubleClick={handleDoubleClick}
      />
    </div>
  );
});

export default News;
