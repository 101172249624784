import { useEffect, useRef, useState } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import Editor from "./../../../components/editor/editor";

import FormCreateUpdate, { getLanguageIndex } from "./../pages/form/form";
import styled from "styled-components";
import { getValidationURLS } from "../../../utils/getValidationURLS";
import { IContentRef, IID, IPage } from "./types";

const EditPage = observer(() => {
  const contentRuRef = useRef<IContentRef | null>(null);
  const contentEnRef = useRef<IContentRef | null>(null);
  const [isHasText, setIsHasText] = useState<boolean>(false);

  const history = useHistory();
  const { id } = useParams<IID>();
  const userStore = useInjection(UserStore);

  const ruIndex = userStore.page && getLanguageIndex(userStore.page, "ru");
  const enIndex = userStore.page && getLanguageIndex(userStore.page, "en");

  useEffect(() => {
    userStore.GetPage(id);
  }, []);

  useEffect(() => {
    if (userStore.page) {
      contentRuRef.current = userStore?.page?.translations[ruIndex]?.content;
      contentEnRef.current = userStore?.page?.translations[enIndex]?.content;
      setIsHasText(true);
    }
  }, [userStore.page]);

  const getAllPages = (): void => {
    !userStore.allPages && userStore.GetAllPages();
  };

  const validationURLs = getValidationURLS(userStore.allPages);

  const handleSubmit = (
    textRu: IContentRef | string,
    textEn: IContentRef | string,
    postData: IPage
  ): void => {
    const isUrlValidate = validationURLs.includes(postData.url);

    if (isUrlValidate && postData._id !== id) {
      toast.error("Указанный url уже существует");
      return;
    }

    const ruIndex = getLanguageIndex(postData, "ru");
    const enIndex = getLanguageIndex(postData, "en");

    postData.translations[ruIndex].content = textRu;
    postData.translations[enIndex].content = textEn;

    userStore
      .EditPage(postData, id)
      .then(() => {
        toast.success("Страница отредактирована");
        history.goBack();
      })
      .catch((err) => {
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };

  useEffect(() => {
    getAllPages();
  }, []);

  if (!userStore.page || !isHasText) {
    return <>Loading</>;
  }

  return (
    <>
      <Title className="lk-title">Редактирование страницы</Title>

      <EditorWrapper>
        <EditorWrapperItem>
          <SubTitle className="lk-title">(Русская версия)</SubTitle>
          <Editor
            forwardedRef={contentRuRef}
            initialState={userStore?.page?.translations[ruIndex]?.content}
          />
        </EditorWrapperItem>

        <EditorWrapperItem>
          <SubTitle className="lk-title">(English version)</SubTitle>
          <Editor
            forwardedRef={contentEnRef}
            initialState={userStore?.page?.translations[enIndex]?.content}
          />
        </EditorWrapperItem>
      </EditorWrapper>

      <FormCreateUpdate
        handleSubmit={(postData) =>
          handleSubmit(contentRuRef.current, contentEnRef.current, postData)
        }
        initialValues={userStore?.page}
        isCreate={false}
      />
    </>
  );
});

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;
export const EditorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EditorWrapperItem = styled.div`
  width: 49%;
`;
export const SubTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

export default EditPage;
