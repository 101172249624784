import { observer } from "mobx-react";
import { useState } from "react"
import {LoginData} from '../../../interfaces/auth'
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router";





const CreateUserForrm = observer(() => {
    const history = useHistory()
    const [formData, setFormData] = useState<LoginData>({
        login: '',
        password: ''
    })
    const store = useInjection(UserStore);

    const onChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }



    const onSubmit = (e: any) => {
        e.preventDefault();
        console.log(formData, 'form data')
        //store call
        if(formData){
            store.Login(formData).then((res) => history.push('/admin/users'))
        }
    }
   
    return (
        <form onSubmit={onSubmit} style={{display: 'flex', left:'40vw', top: "30vh", flexDirection: 'column', position: "absolute", width: "200px"}}>
            <h4 style={{textAlign: "center"}}>Админ логин</h4>

            <input type='text' name='login' onChange={onChange} placeholder={'login'} /> <br />
            <input type='text' name='password' onChange={onChange} placeholder={'password'} /> <br />
            <Button color='primary' type="submit"> Войти</Button>
        </form>
    )
})


export default CreateUserForrm