import { useRef, useState } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import Editor from "./../../../components/editor/editor";
import { useEffect } from "react";
import { observer } from "mobx-react";
import FormCreateUpdate, { getLanguageIndex } from "./form/form";
import styled from "styled-components";
import { IContentRef, IID, INews } from "./types";

const EditPost = observer(() => {
  const contentRuRef = useRef<IContentRef | null>(null);
  const contentEnRef = useRef<IContentRef | null>(null);
  const [isHasText, setIsHasText] = useState<boolean>(false);

  const history = useHistory();
  const { id } = useParams<IID>();

  const userStore = useInjection(UserStore);

  const ruIndex = userStore.post && getLanguageIndex(userStore.post, "ru");
  const enIndex = userStore.post && getLanguageIndex(userStore.post, "en");

  useEffect(() => {
    userStore.GetPost(id);
  }, []);

  useEffect(() => {
    if (userStore.post) {
      contentRuRef.current = userStore?.post?.translations[ruIndex]?.text;
      contentEnRef.current = userStore?.post?.translations[enIndex]?.text;
      setIsHasText(true);
    }
  }, [userStore.post]);

  const handleSubmit = (
    textRu: IContentRef | string,
    textEn: IContentRef | string,
    postData: INews
  ): void => {
    const ruIndex = getLanguageIndex(postData, "ru");
    const enIndex = getLanguageIndex(postData, "en");

    postData.translations[ruIndex].text = textRu;
    postData.translations[enIndex].text = textEn;

    const formDataNFT = new FormData();

    formDataNFT.append("translations", JSON.stringify(postData.translations));
    if (postData.news) {
      formDataNFT.append("news", postData.news);
    }

    userStore
      .EditPost(formDataNFT, id)
      .then(() => {
        toast.success("Новость отредактирована");
        history.goBack();
      })
      .catch((err) => {
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };

  if (!userStore.post || !isHasText) {
    return <>Loading</>;
  }

  return (
    <>
      <Title className="lk-title">Редактирование новости</Title>

      <EditorWrapper>
        <EditorWrapperItem>
          <SubTitle className="lk-title">(Русская версия)</SubTitle>
          <Editor
            forwardedRef={contentRuRef}
            initialState={userStore?.post?.translations[ruIndex]?.text}
          />
        </EditorWrapperItem>

        <EditorWrapperItem>
          <SubTitle className="lk-title">(English version)</SubTitle>
          <Editor
            forwardedRef={contentEnRef}
            initialState={userStore?.post?.translations[enIndex]?.text}
          />
        </EditorWrapperItem>
      </EditorWrapper>

      <FormCreateUpdate
        handleSubmit={(postData) => {
          handleSubmit(contentRuRef.current, contentEnRef.current, postData);
        }}
        initialValues={userStore?.post}
        fileRequired={false}
        isCreate={false}
      />
    </>
  );
});

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;
export const EditorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const EditorWrapperItem = styled.div`
  width: 49%;
`;
export const SubTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

export default EditPost;
