import "react-quill/dist/quill.snow.css"; // ES6
import { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import "./index.css";

import { useInjection } from "inversify-react";
import { UserStore } from "../../stores/user/UserStore";
import { IEditor } from "./types";

Quill.register("modules/imageResize", ImageResize);

/*
 * Simple editor component that takes placeholder text as a prop
 */
const Editor: React.FC<IEditor> = ({
  forwardedRef,
  initialState = "",
  placeholder = "Введите контент новости",
}) => {
  const userStore = useInjection(UserStore);

  const editorRef = useRef<any>();

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        saveToServer(file);
      } else {
        console.warn("You could only upload images.");
      }
    };
  };

  const saveToServer = async (file: File): Promise<void> => {
    const fd = new FormData();
    fd.append("news", file);

    const url = await userStore.UploadFile(fd);
    insertToEditor(url);
  };

  const insertToEditor = (url: string): void => {
    const range = editorRef.current.getEditorSelection();
    editorRef.current.getEditor().insertEmbed(range, "image", url);
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };

  const handleChange = (html: string): void => {
    forwardedRef.current = html;
  };

  return (
    <>
      <ReactQuill
        ref={editorRef}
        onChange={handleChange}
        value={initialState}
        modules={modules}
        formats={formats}
        bounds={"#root"}
        placeholder={placeholder}
      />
    </>
  );
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
];

export default Editor;
