import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { NavLink, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { UserStore } from "../../../stores/user/UserStore";

const Container = styled.div`
  width: 100%;
  height: 65px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-image: url(${require("../../../images/backgroundGradient.png")});
  background-size: cover;
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: repeat(10, max-content) 1fr max-content;
  column-gap: 40px;
  align-items: center;
  padding: 0 20px;
  height: 100%;

  & a {
    text-decoration: none;
    color: black;
    border-bottom: 2px solid transparent;
    width: fit-content;
  }
  & button {
    background-color: #83548a;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
  }
`;

const Navbar = observer(() => {
  let match = useRouteMatch();

  const LogOut = () => {
    localStorage.removeItem("token-admin");
    return window.location.reload();
  };

  return (
    <Container>
      <Flex>
        {Links.map((link, index) => {
          return (
            <NavLink
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "none",
                borderColor: "black",
              }}
              to={`${match.path}/${link.path}`}
              key={index}
            >
              {link.title}
            </NavLink>
          );
        })}
        <span />
        <button onClick={() => LogOut()}>Logout</button>
      </Flex>
    </Container>
  );
});

export default Navbar;

const Links: ILinks[] = [
  {
    title: "Пользователи",
    path: "users",
  },
  {
    title: "NFT",
    path: "nft",
  },
  {
    title: "Заявки",
    path: "request",
  },
  {
    title: "Новости",
    path: "news",
  },
  {
    title: "Теги",
    path: "tags",
  },
  {
    title: "Страницы",
    path: "pages",
  },
  {
    title: "Катеогрии",
    path: "categories",
  },
  {
    title: "Разделы профиля",
    path: "chapters",
  },
  {
    title: "Настройки поиска",
    path: "search",
  },
  {
    title: "Отзывы",
    path: "reviews",
  },
];

interface ILinks {
  title: string;
  path: string;
}
