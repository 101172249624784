import { useEffect, useState } from "react";
import styled from "styled-components";
import ReactStars from "react-rating-stars-component";
import { useInjection } from "inversify-react";
import { UserStore } from "./../../../../stores/user/UserStore";
import { observer } from "mobx-react-lite";
import { Button } from "react-bootstrap";

const SORT_OPTIONS = [
  {
    value: "",
    label: "Все",
  },
  {
    value: "isNotAprowe",
    label: "На модерации",
  },
];

export interface IReview {
  type: string;
  name: string;
  _id: string;
  grade: number;
  whatLike: string;
  whatNotLike: string;
  comment: string;
}

interface IParamsDetail {
  review: IReview;
  refetch: () => void;
}

const ReviewPage = observer(() => {
  const [sort, setSort] = useState("");

  const store = useInjection(UserStore);

  const handleSort = (e) => {
    setSort(e.target.value);
  };

  const getReviews = () => {
    const params = sort ? { isApprowe: false } : {};

    store.getAllReviews(params);
  };

  useEffect(() => {
    getReviews();
  }, [sort]);

  store.reviews && console.log(JSON.parse(JSON.stringify(store.reviews)));

  return (
    <div>
      <h5>Список отзывов</h5>

      <SortWrapper>
        <div className="input-group mb-3">
          <label className="input-group-text w-50" htmlFor="token-rights">
            Сортировка отзывов
          </label>
          <select
            className="form-select"
            id="token-rights"
            name="section"
            onChange={handleSort}
            value={sort}
          >
            {SORT_OPTIONS?.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </SortWrapper>

      <ReviewListWrapper>
        {/* One review */}
        {store.reviews &&
          store.reviews?.map((review, index) => (
            <ReviewDetail review={review} key={index} refetch={getReviews} />
          ))}

        {/* One review */}
      </ReviewListWrapper>
    </div>
  );
});

export default ReviewPage;

const ReviewDetail: React.FC<IParamsDetail> = ({ review, refetch }) => {
  const [isLike, setIsLike] = useState(
    review?.type === "negative" ? false : true
  );

  const store = useInjection(UserStore);

  const handleApprowe = () => {
    store
      .approweReview(review?._id, {
        isApprowe: true,
        type: isLike ? "positive" : "negative",
      })
      .then(() => refetch());
  };

  const handleDelete = () => {
    store.deleteReview(review?._id).then(() => refetch());
  };

  const isNotApprowe = review?.type === "notIndificate";

  return (
    <ReviewsListItem>
      <RewiewContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "20px",
            marginBottom: "20px",
          }}
        >
          <ReviewsListItemName>{review?.name}</ReviewsListItemName>
          <ReactStars
            size={24}
            color={"#EDF2F6"}
            edit={false}
            value={review?.grade || 5}
          />

          <LikesWrapper isNotApprowe={isNotApprowe}>
            <img
              src={require("./../../../../images/like-button.png")}
              alt="like"
              style={{ opacity: isLike ? 1 : 0.3 }}
              onClick={() => isNotApprowe && setIsLike(true)}
            />

            <img
              src={require("./../../../../images/dislike-button.png")}
              alt="dislike"
              style={{ opacity: !isLike ? 1 : 0.3 }}
              onClick={() => isNotApprowe && setIsLike(false)}
            />
          </LikesWrapper>
        </div>
        <ReviewsListItemComment>
          Понравилось: {review?.whatLike}
        </ReviewsListItemComment>
        <ReviewsListItemComment>
          Не понравилось: {review?.whatNotLike}
        </ReviewsListItemComment>
        <ReviewsListItemComment>
          Коментарий: {review?.comment}
        </ReviewsListItemComment>
      </RewiewContent>

      <ButtonsWrapper>
        {isNotApprowe && <Button onClick={handleApprowe}>Одобрить</Button>}
        <Button onClick={handleDelete}>Удалить</Button>
      </ButtonsWrapper>
    </ReviewsListItem>
  );
};

const SortWrapper = styled.div`
    width: 500px;
    display: flexж
    justify-content: flex-end;
`;

const ReviewListWrapper = styled.div`
  margin-top: 70px;
`;

const ReviewsListItem = styled.div`
  width: 100%;
  padding: 30px !important;
  border: 1px solid #00000050 !important;
  border-radius: 16px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

const RewiewContent = styled.div`
  width: calc(100% - 330px);
  margin-right: 30px;
`;

const ReviewsListItemName = styled.div`
  font-size: 26px;
  font-weight: 500;
  margin-right: 14px;
`;

const LikesWrapper = styled.div`
  display: flex;
  margin-left: 70px;

  & img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: block;
    cursor: ${(props: { isNotApprowe: boolean }) =>
      props.isNotApprowe ? "pointer" : "auto"};
  }
`;

const ReviewsListItemComment = styled.div`
  font-size: 16px;
  margin-top: 12px;
  line-height: 1.5em;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;

const ButtonsWrapper = styled.div`
  & button {
    display: block;
    margin-bottom: 20px;
    width: 200px;
  }
`;
