import { useRef } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import Editor from "./../../../components/editor/editor";
import FormCreateUpdate, { getLanguageIndex } from "./form/form";
import styled from "styled-components";
import { IContentRef, INews } from "./types";

const CreatePost = () => {
  const contentRuRef = useRef<IContentRef | null>(null);
  const contentEnRef = useRef<IContentRef | null>(null);
  const history = useHistory();
  const userStore = useInjection(UserStore);

  const handleSubmit = (
    textRu: IContentRef | string,
    textEn: IContentRef | string,
    postData: INews
  ): void => {
    const ruIndex = getLanguageIndex(postData, "ru");
    const enIndex = getLanguageIndex(postData, "en");

    postData.translations[ruIndex].text = textRu;
    postData.translations[enIndex].text = textEn;

    const formDataNFT = new FormData();

    formDataNFT.append("translations", JSON.stringify(postData.translations));
    formDataNFT.append("news", postData.news);

    userStore
      .AddPost(formDataNFT)
      .then(() => {
        toast.success("Новость добавлена");
        history.goBack();
      })
      .catch((err) => {
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };

  return (
    <>
      <Title className="lk-title">Создание новости</Title>

      <EditorWrapper>
        <EditorWrapperItem>
          <SubTitle className="lk-title">(Русская версия)</SubTitle>
          <Editor forwardedRef={contentRuRef} />
        </EditorWrapperItem>

        <EditorWrapperItem>
          <SubTitle className="lk-title">(English version)</SubTitle>
          <Editor forwardedRef={contentEnRef} />
        </EditorWrapperItem>
      </EditorWrapper>

      <FormCreateUpdate
        handleSubmit={(postData: INews) => {
          handleSubmit(contentRuRef.current, contentEnRef.current, postData);
        }}
      />
    </>
  );
};

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;
export const EditorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EditorWrapperItem = styled.div`
  width: 49%;
`;
export const SubTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

export default CreatePost;
