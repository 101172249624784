import styled from "styled-components";
import { url } from "../auth/helper";

export interface ITableParams {
  headCells: IHeadCell[];
  rows: ITableRow[];
  handleChange?: (id: string) => void;
  handleRemove: (id: string) => void;
  handleDoubleClick?: (id: string) => void;
}

export interface ITableListHeaderParams {
  headCells: IHeadCell[];
}

export interface ITableRowParams {
  headCells: IHeadCell[];
  row: ITableRow;
  handleChange?: (id: string) => void;
  handleRemove: (id: string) => void;
  handleDoubleClick: (id: string) => void;
}

export interface IHeadCell {
  id: string;
  label: string;
}
export interface ITableRow {
  id: string;
  isVisible?: boolean;
  name?: string;
  title?: string;
  description?: string;
  image?: string;
}

export const Table: React.FC<ITableParams> = ({
  headCells,
  rows,
  handleChange,
  handleRemove,
  handleDoubleClick,
}) => {
  return (
    <table className="table">
      <TableListHeader headCells={headCells} />
      <tbody>
        {rows.map((row) => {
          return (
            <TableRow
              headCells={headCells}
              row={row}
              handleChange={handleChange}
              handleRemove={handleRemove}
              handleDoubleClick={handleDoubleClick}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const TableListHeader: React.FC<ITableListHeaderParams> = ({ headCells }) => {
  return (
    <thead>
      <tr>
        {headCells.map((column) => (
          <th scope="col" key={column.id}>
            {column.label}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const TableRow: React.FC<ITableRowParams> = ({
  headCells,
  row,
  handleChange,
  handleRemove,
  handleDoubleClick,
}) => {
  return (
    <TR onDoubleClick={() => handleDoubleClick(row.id)}>
      {headCells.map((column) => {
        const value = row[column.id];
        console.log(column.id, value);
        return (
          <td key={column.id}>
            {column?.id === "isVisible" ? (
              <input
                type="checkbox"
                onChange={() => {
                  handleChange(row.id);
                }}
                checked={value}
              />
            ) : column?.id === "deleted" ? (
              <DeletedButton onClick={() => handleRemove(row.id)}>
                x
              </DeletedButton>
            ) : column?.id === "image" ? (
              <img src={url + "/" + value} width="150px" height={"100px"} />
            ) : (
              value
            )}
          </td>
        );
      })}
    </TR>
  );
};

export default Table;

const TR = styled.tr`
  &:hover {
    background-color: #e4e4e4;
  }
`;

const DeletedButton = styled.div`
  cursor: pointer;
  width: 40px;
`;
