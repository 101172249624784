
import CreateUserForrm from "./create/form"
import 'bootstrap/dist/css/bootstrap.min.css';

const Auth = () => {
    return (
        <div>
            <CreateUserForrm />
        </div>

    )
}

export default Auth