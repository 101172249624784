import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { UserStore } from "../../../stores/user/UserStore";
import InputForm from "./../news/form/inputForm";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { toast } from "react-toastify";
import Table, { ITableRow } from "./../../../components/table/table";

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const createData = ({ _id, isVisible, name }: ISetting): ITableRow => {
  return {
    id: _id,
    isVisible,
    name,
  };
};

const SETTINGS_TABLE_HEAD_OPTIONS = [
  { id: "name", label: "Название" },
  { id: "isVisible", label: "Отображение" },
  // { id: "deleted", label: "Удалить" },
];

const initialState = {
  entity: "",
  name: "",
  isVisible: false,
};

export interface ISetting {
  _id?: string;
  entity?: string;
  isVisible: boolean;
  name: string;
}

const SearchSettings = observer(() => {
  const [newSetting, setNewSetting] = useState<ISetting>(initialState);
  const [settings, setSettings] = useState<ISetting[] | null>(null);
  const [rows, setRows] = useState<ITableRow[]>([]);

  const store = useInjection(UserStore);

  useEffect(() => {
    store.getAllSearchSettings();
  }, []);

  useEffect(() => {
    store?.searchSettings && setSettings(store?.searchSettings);
  }, [store?.searchSettings]);

  useEffect(() => {
    settings && setRows(settings?.map((chapter) => createData(chapter)));
  }, [settings]);

  const submit = (e) => {
    e.preventDefault();

    store
      .addNewSearchSetting(newSetting)
      .then(() => {
        setNewSetting({ ...initialState });
      })
      .then(() => {
        toast.success("Поисковый параметр добавлен");
      });
  };

  const handleRemove = (id: string) => {
    store.removeSetting(id).then(() => {
      toast.success("Поисковый параметр удалён");
    });
  };

  const handleChange = (id: string): void => {
    const index = settings?.map((item) => item._id).indexOf(id);
    let newSettingsValue = [...settings];
    newSettingsValue[index].isVisible = !newSettingsValue[index].isVisible;
    setSettings(newSettingsValue);
  };

  const handleSubmit = (): void => {
    const settingsEditFormData = {
      search: settings,
    };
    store.editSettings(settingsEditFormData).then(() => {
      toast.success("Поисковый параметры отредактированы");
    });
  };

  return (
    <div>
      <ControlsWrapper>
        <h5>Список параметров поиска</h5>
        <Button onClick={handleSubmit}>Сохранить изминения</Button>
      </ControlsWrapper>
      {/* <form onSubmit={submit}>
        <div style={{ width: 500 }}>
          <InputForm
            name="setting-param"
            title="Значение параметра"
            inputValue={newSetting.entity}
            required={true}
            handleChange={(e) =>
              setNewSetting((prev) => ({ ...prev, entity: e.target.value }))
            }
          />
          <InputForm
            name="setting-name"
            title="Название параметра"
            inputValue={newSetting.name}
            required={true}
            handleChange={(e) =>
              setNewSetting((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </div>

        <Button type="submit">Создать параметр</Button>
      </form> */}

      <br />
      <br />

      <Table
        headCells={SETTINGS_TABLE_HEAD_OPTIONS}
        rows={rows}
        handleRemove={handleRemove}
        handleChange={handleChange}
      />
    </div>
  );
});

export default SearchSettings;
