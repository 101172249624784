import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { UserStore } from "../../../stores/user/UserStore";
import InputForm from "./../news/form/inputForm";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { toast } from "react-toastify";
import Table, { ITableRow } from "./../../../components/table/table";

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const createData = ({ _id, isVisible, name }: IChapter): ITableRow => {
  return {
    id: _id,
    isVisible,
    name,
  };
};

const CHAPTERS_TABLE_HEAD_OPTIONS = [
  { id: "name", label: "Название" },
  { id: "isVisible", label: "Отображение" },
  // { id: "deleted", label: "Удалить" },
];

const initialState = {
  path: "",
  name: "",
  isVisible: false,
};

export interface IChapter {
  _id?: string;
  path?: string;
  isVisible: boolean;
  name: string;
}

const ProfileChapters = observer(() => {
  const [newChapter, setNewChapter] = useState<IChapter>(initialState);
  const [chapters, setChapters] = useState<IChapter[] | null>(null);
  const [rows, setRows] = useState<ITableRow[]>([]);

  const store = useInjection(UserStore);

  useEffect(() => {
    store.getAllChapter();
  }, []);

  useEffect(() => {
    store?.chapters && setChapters(store?.chapters);
  }, [store?.chapters]);

  useEffect(() => {
    chapters && setRows(chapters?.map((chapter) => createData(chapter)));
  }, [chapters]);

  const submit = (e) => {
    e.preventDefault();

    store
      .addNewChapter(newChapter)
      .then(() => {
        setNewChapter({ ...initialState });
      })
      .then(() => {
        toast.success("Раздел добавлен");
      });
  };

  const handleRemove = (id: string) => {
    store.removChapter(id).then(() => {
      toast.success("Раздел удалён");
    });
  };

  const handleChange = (id: string): void => {
    const index = chapters?.map((item) => item._id).indexOf(id);
    let newChaptersValue = [...chapters];
    newChaptersValue[index].isVisible = !newChaptersValue[index].isVisible;
    setChapters(newChaptersValue);
  };

  const handleSubmit = (): void => {
    const chaptersEditFormData = {
      sections: chapters,
    };
    store.editChapters(chaptersEditFormData).then(() => {
      toast.success("Разделы отредактированы");
    });
  };

  return (
    <div>
      <ControlsWrapper>
        <h5>Список разделов</h5>
        <Button onClick={handleSubmit}>Сохранить изминения</Button>
      </ControlsWrapper>
      {/* <form onSubmit={submit}>
        <div style={{ width: 500 }}>
          <InputForm
            name="chapter-url"
            title="URL раздела профиля"
            inputValue={newChapter.path}
            required={true}
            handleChange={(e) =>
              setNewChapter((prev) => ({ ...prev, path: e.target.value }))
            }
          />
          <InputForm
            name="chapter-name"
            title="Название"
            inputValue={newChapter.name}
            required={true}
            handleChange={(e) =>
              setNewChapter((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </div>

        <Button type="submit">Создать раздел</Button>
      </form> */}

      <br />
      <br />

      <Table
        headCells={CHAPTERS_TABLE_HEAD_OPTIONS}
        rows={rows}
        handleRemove={handleRemove}
        handleChange={handleChange}
      />
    </div>
  );
});

export default ProfileChapters;
