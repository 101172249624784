import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { UserStore } from "../../../stores/user/UserStore";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const BG = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;
export const Container = styled.div`
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  border-radius: 26px;
  padding: 64px 80px;
  position: relative;
  width: 598px;
  display: flex;
  flex-direction: column;

  & .modalCross {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 32px;
    right: 26px;
  }
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
`;
const InputHolder = styled.div`
  position: relative;
  margin-top: 18px;

  & div {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
  }
  & input {
    height: 44px;
    width: 100%;
    border: 0.5px solid #a1a1a1b8;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 12px;
  }
  & textarea {
    height: 100px;
    width: 100%;
    border: 0.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    resize: none;
  }
  & img {
    position: absolute;
    right: 16px;
    bottom: 12px;
  }
`;
export const SubmitButton = styled.button`
  height: 50px;
  background: #8a4ada;
  border-radius: 8px;
  border: none;
  color: white;
  width: 100%;
  margin-top: 25px;
  cursor: pointer;

  & img {
    height: 1.1rem;
    margin-right: 10px;
  }
`;

const CreateUser = observer(({ close }: any) => {
  const store = useInjection(UserStore);

  const history = useHistory();
  const [formData, setFormData] = useState({
    public_key: "",
    fullname: "",
    phone: "",
    email: "",
    password: "",
    referal: localStorage.getItem("ref"),
  });
  const [password, setPassword] = useState();

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const RepeatPassword = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    store.GetUsers();
  }, []);

  const onCreate = (e) => {
    //modal here
    e.preventDefault();
    if (localStorage.getItem("ref")) {
      setFormData({ ...formData, referal: localStorage.getItem("ref") });
    }
    if (password !== formData.password)
      return toast.error("Пароли не совпадают");
    console.log(formData, "form");
    store.AddUser(formData);
    close();
  };

  return (
    <BG onClick={close}>
      <Container onClick={(e) => e.stopPropagation()}>
        <form onSubmit={onCreate}>
          <Title>Новый пользователь</Title>
          <InputHolder>
            <div>ФИО</div>
            <input onChange={onChange} name="fullname" />
          </InputHolder>
          <InputHolder>
            <div>E-mail</div>
            <input onChange={onChange} name="email" />
          </InputHolder>
          <InputHolder>
            <div>Телефон:</div>
            <input onChange={onChange} name="phone" />
          </InputHolder>
          <InputHolder>
            <div>Пароль:</div>
            <input onChange={onChange} name="password" />
          </InputHolder>
          <InputHolder>
            <div>Пароль еще раз:</div>
            <input onChange={RepeatPassword} name="repeat_pass" />
          </InputHolder>
          <SubmitButton type="submit">Создать</SubmitButton>
        </form>
      </Container>
    </BG>
  );
});

export default CreateUser;
