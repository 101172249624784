import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import styled from "styled-components";
import Web3 from "web3";
import { UserStore } from "../../../stores/user/UserStore";
import { toast } from "react-toastify";

const TR = styled.tr`
  &:hover {
    background-color: #ebebeb;
  }
`;

const UpdateTDWrapper = styled.div`
  display: flex;

  & button {
    margin-left: 20px;
  }

  & span {
    display: inline-block;
    width: 40px;
  }

  & input {
    width: 40px;
  }
`;

const NFT = observer(() => {
  const history = useHistory();
  const store = useInjection(UserStore);
  const [nftId, setNFTid] = useState(null);

  let web3 = new Web3(process.env.RPC_URL_56);

  useEffect(() => {
    store.GetAllNFT();
    return () => setNFTid(null);
  }, []);

  const onChange = (e) => {
    switch (e.target.value) {
      case "onOpen":
        return onOpen();
      case "onDelete":
        return onDelete();
      default:
        "";
    }
  };

  const onOpen = () => {
    history.push(`/admin/nft/${nftId}`);
  };

  const onDelete = () => {
    if (window.confirm(`Вы уверены что хотите удалить НФТ ?`)) {
      console.log(nftId);
      store.deleteNFT(nftId);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <h5>Список всех NFT</h5>
        <Button onClick={() => history.push(`/admin/createNFT`)}>
          Создать NFT
        </Button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Название</th>
            <th scope="col">Стоимость</th>
            <th scope="col">Категория</th>
            <th scope="col">Автор</th>
            <th scope="col">Соавтор</th>
            <th scope="col">short ID</th>
            <th scope="col">Индекс приоритетности</th>
            <th scope="col">Опции</th>
          </tr>
        </thead>
        <tbody>
          {store?.allNFT ? (
            store.allNFT.map((item, index) => {
              return (
                <TR
                  key={index + "p"}
                  // onDoubleClick={() => history.push(`/admin/nft/${item?._id}`)}
                  onMouseOver={() => setNFTid(item._id)}
                >
                  <th scope="row">{item?.title}</th>
                  <td>
                    {web3.utils.fromWei(item?.price?.toString(), "ether")} BNB
                  </td>

                  <td>{item?.category}</td>
                  <td>{item?.author?.fullname}</td>
                  <td>{item?.coauthor}</td>
                  <td>{item?.newId ? item.newId : "-"}</td>
                  <UpdateTd item={item} />
                  <td>
                    <select onChange={onChange}>
                      <option>Опции</option>
                      <option value="onOpen">Подробнее</option>
                      <option value="onOpen">Редактировать</option>
                      <option value="onDelete">Удалить</option>
                    </select>
                  </td>
                </TR>
              );
            })
          ) : (
            <tr>
              <td>loading</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
});

const UpdateTd = ({ item }) => {
  const store = useInjection(UserStore);

  const [isUpdate, setIsApdate] = useState(false);
  const [indexValue, setIndexValue] = useState(String(item.sortIndex));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndexValue(e.target.value);
  };

  const handleEdit = () => {
    setIsApdate(!isUpdate);
  };

  const handleSubmit = () => {
    store
      .UpdateNFT({ sortIndex: Number(indexValue) }, item?._id)
      .then(() => {
        setIsApdate(!isUpdate);
      })
      .catch((err) => {
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      });
  };

  return (
    <td>
      <UpdateTDWrapper>
        {!isUpdate ? (
          <>
            <span>{item?.sortIndex}</span>
            <Button onClick={handleEdit}>Редактировать</Button>
          </>
        ) : (
          <>
            <input type="number" value={indexValue} onChange={handleChange} />
            <Button onClick={handleSubmit}>Применить</Button>
          </>
        )}
      </UpdateTDWrapper>
    </td>
  );
};

export default NFT;
