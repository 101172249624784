import Navbar from "./component/Navbar"
import styled  from "styled-components"
import Header from "./component/Header";



const Container = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;

    & .children {
        padding: 64px;
    }
`

const Layout = ({ children }: any) => {


    return (
        <>
        <Container>
            {/* <Header /> */}
          <Navbar />
          <div className='children'>
            {children}
          </div>
        </Container>
        </>

    )
    
}


export default Layout