import { SubmitButton } from "../../users/createUserModal";
import Web3 from "web3";
import { useState } from "react";
import { useInjection } from "inversify-react";
import { UserStore } from "../../../../stores/user/UserStore";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import "./stylesNFT.scss";
import "./all.css";
import "./styles.css";
import "./profile.sass";
import { useEffect } from "react";
import { observer } from "mobx-react";
import Loader from "./../../../../components/loader/loader";

const CreateNFT = observer(({ close }: any) => {
  const [byNow, setBynow] = useState(true);
  const [auc, setAuc] = useState(false);

  let web3 = new Web3(process.env.RPC_URL_56);

  const showBuynow = () => {
    setBynow(true);
    setAuc(false);
  };
  const showAuction = (e) => {
    toast.error("Аукцион недоступен на данный момент");
  };

  const userStore = useInjection(UserStore);
  const history = useHistory();
  const [nftData, setNFTdata] = useState({
    title: "",
    about: "",
    price: "",
    category: "",
    file: null,
    preview: null,
    sortIndex: "1",
  });

  const [loader, setLoader] = useState(false);

  const onChange = (e) => {
    setNFTdata({ ...nftData, [e.target.name]: e.target.value });
  };
  const fileUpload = (e) => {
    e.preventDefault();
    setNFTdata({ ...nftData, file: e.target.files[0] });
    toast.success("Файл загружен");
  };
  const previewUpload = (e) => {
    e.preventDefault();
    setNFTdata({ ...nftData, preview: e.target.files[0] });
    toast.success("Файл загружен");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const formDataNFT = new FormData();
    for (const [key, value] of Object.entries(nftData)) {
      if (key === "price") {
        formDataNFT.append(key, web3.utils.toWei(String(value), "ether"));
      } else {
        formDataNFT.append(key, value);
      }
    }
    userStore
      .AddNFT(formDataNFT)
      .then((res) => {
        toast.success("NFT создан");
        history.goBack();
      })
      .catch((err) => {
        let msg = err.response.data.err;
        err?.message && toast.error(msg);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <div className="mainBlock">
        <form onSubmit={onSubmit}>
          <div className="lk-title">Создание NFT</div>
          <div className="my-3 w-100" role="group" aria-label="NFT type">
            <div className="row my-3 d-flex ">
              <div className="col-md-3 d-flex align-items-center ">
                <input
                  required
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-image"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-image"
                  onClick={() =>
                    setNFTdata({ ...nftData, category: "Изображение" })
                  }
                >
                  <i className="far fa-image" />
                  <br />
                  Изображение
                </label>
              </div>
              <div className="col-md-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-panimage"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-panimage"
                  onClick={() =>
                    setNFTdata({
                      ...nftData,
                      category: "Панорамное изображение",
                    })
                  }
                >
                  <i className="fas fa-globe" />
                  <br />
                  Панорамное изображение
                </label>
              </div>
              <div className="col-md-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-vector"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-vector"
                  onClick={() =>
                    setNFTdata({
                      ...nftData,
                      category: "Векторное изображение",
                    })
                  }
                >
                  <i className="fas fa-bezier-curve" />
                  <br />
                  Векторное изображение
                </label>
              </div>
              <div className="col-md-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-video"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-video"
                  onClick={() => setNFTdata({ ...nftData, category: "Видео" })}
                >
                  <i className="fas fa-film" />
                  <br />
                  Видео
                </label>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-panvideo"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-panvideo"
                  onClick={() =>
                    setNFTdata({ ...nftData, category: "Панорамное видео" })
                  }
                >
                  <i className="fas fa-video" />
                  <br />
                  Панорамное видео
                </label>
              </div>
              <div className="col-md-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-3dvideo"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-3dvideo"
                  onClick={() =>
                    setNFTdata({ ...nftData, category: "3D Видео" })
                  }
                >
                  <i className="fas fa-vr-cardboard" />
                  <br />
                  3Д Видео
                </label>
              </div>
              <div className="col-md-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-3dmodel"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-3dmodel"
                  onClick={() =>
                    setNFTdata({ ...nftData, category: "3D Модель" })
                  }
                >
                  <i className="fab fa-unity" />
                  <br />
                  3Д Модель
                </label>
              </div>
              <div className="col-md-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="contenttype"
                  id="ctype-interactive"
                  autoComplete="off"
                />
                <label
                  className="itemtype btn btn-outline-primary"
                  htmlFor="ctype-interactive"
                  onClick={() =>
                    setNFTdata({
                      ...nftData,
                      category: "Интерактивный контент",
                    })
                  }
                >
                  <i className="fas fa-gamepad" />
                  <br />
                  Интерактивный контент
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-8">
              <div className="row justify-content-center my-3">
                <div className="col-md-10">
                  <div className="input-group my-3">
                    <span
                      className="input-group-text w-25"
                      id="token-name-desc"
                    >
                      Название *
                    </span>
                    <input
                      required
                      name="title"
                      type="text"
                      onChange={onChange}
                      className="form-control"
                      id="token-name"
                      aria-describedby="token-name-desc"
                    />
                  </div>

                  <div className="input-group my-3">
                    <span
                      className="input-group-text w-25"
                      id="token-name-desc"
                    >
                      Индекс приоритетности *
                    </span>
                    <input
                      required
                      name="sortIndex"
                      type="number"
                      onChange={onChange}
                      className="form-control"
                      id="token-name"
                      value={nftData.sortIndex}
                      aria-describedby="token-name-desc"
                    />
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-10"></div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row justify-content-center my-3">
                <div className="col-md-10">
                  <h3>Описание объекта</h3>
                  <div className="form-floating">
                    <textarea
                      required
                      name="about"
                      onChange={onChange}
                      className="form-control"
                      placeholder="Введите здесь описание токена"
                      id="token-description"
                      style={{ height: "200px" }}
                      maxLength={1000}
                      defaultValue={""}
                    />
                    <label htmlFor="token-description">
                      формат / размер / стиль исполнения / дата создания и пр..
                    </label>
                  </div>
                  <small>Максимум 1000 символов</small>
                </div>
              </div>
              <hr />
              <div className="row justify-content-center my-3">
                <div className="col-md-10">
                  <h3>Файл превью</h3>
                  <div
                    className="file-drop-area"
                    style={{
                      backgroundColor: nftData.preview ? "#DAF9D2" : "",
                    }}
                  >
                    {!nftData.preview ? (
                      <>
                        <span className="fake-btn">Выберите файл</span>
                        <span className="file-msg">
                          Или перетащите его сюда
                        </span>
                        <input
                          required
                          onChange={previewUpload}
                          className="file-input"
                          type="file"
                          multiple
                        />
                      </>
                    ) : (
                      <>
                        <span className="fake-btn">Выбрать другой файл</span>
                        <span className="file-msg">
                          Или перетащите его сюда
                        </span>
                        <input
                          required
                          onChange={previewUpload}
                          className="file-input"
                          type="file"
                          multiple
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row justify-content-center my-3 ">
                <div className="col-md-10">
                  <h3>Основной файл</h3>
                  <div
                    className="file-drop-area"
                    style={{ backgroundColor: nftData.file ? "#DAF9D2" : "" }}
                  >
                    {!nftData.file ? (
                      <>
                        <span className="fake-btn">Выберите файл</span>
                        <span className="file-msg">
                          Или перетащите его сюда
                        </span>
                        <input
                          required
                          onChange={fileUpload}
                          className="file-input"
                          type="file"
                        />
                      </>
                    ) : (
                      <>
                        <span className="fake-btn">Выбрать другой файл</span>
                        <span className="file-msg">
                          Или перетащите его сюда
                        </span>
                        <input
                          required
                          onChange={fileUpload}
                          className="file-input"
                          type="file"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              {/* <div className="row justify-content-center my-3 ">
              <div className="col-md-10">
                <h3>
                  Скрытый контент
                </h3>
                <div className="file-drop-area">
                  <span className="fake-btn">Выберите файл</span>
                  <span className="file-msg">Или перетащите его сюда</span>
                  <input className="file-input" type="file" multiple />
                </div>
              </div>
            </div> */}
              <div className="row justify-content-center text-center my-3 ">
                <div className="col-6">
                  <SubmitButton type="submit" className="frm_button">
                    Создать NFT
                  </SubmitButton>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {byNow && (
                <div id="block-buynow" className="format-info">
                  <div className="input-group my-3">
                    <span
                      className="input-group-text w-50"
                      id="token-price-fix"
                    >
                      {" Цена, BNB (Wei)*"}
                    </span>
                    <input
                      required
                      name="price"
                      onChange={onChange}
                      type="text"
                      className="form-control"
                      id="token-price-fix"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                </div>
              )}
              {auc && (
                <div id="block-auction" className="format-info">
                  <div className="input-group my-3">
                    <span className="input-group-text w-50" id="price-auc-base">
                      Начальная цена, BNB*
                    </span>
                    <input
                      required
                      type="number"
                      className="form-control"
                      id="price-auc-base"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                  <div className="input-group my-3">
                    <span
                      className="input-group-text w-50"
                      id="price-auc-buyout"
                    >
                      Цена выкупа, BNB*
                    </span>
                    <input
                      required
                      type="number"
                      className="form-control"
                      id="price-auc-buyout"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                  <div className="input-group my-3">
                    <span className="input-group-text w-50" id="price-auc-days">
                      Срок (в днях)
                    </span>
                    <input
                      required
                      type="number"
                      className="form-control"
                      id="price-auc-days"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                </div>
              )}
              <div className="my-3 text-end" id="block-buynow">
                <small>
                  {" "}
                  1 BNB сейчас равен <b>23 787,30</b> руб.
                </small>
              </div>
              <hr />
              <div className="input-group mb-3">
                <label className="input-group-text w-50" htmlFor="token-rights">
                  Объем прав *
                </label>
                <select className="form-select" id="token-rights">
                  <option value="">выберите один из вариантов</option>
                  <option value={1}>Не указано</option>
                  <option value={2}>Исключительные права</option>
                  <option value={3}>Неисключительные права</option>
                </select>
              </div>
              {nftData.category === "Изображение" && (
                <div id="format-img" className="format-info format-show">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - JPG
                  <br />
                  - PNG
                  <br />
                  - TIFF
                  <br />
                  <br />
                  <h6>Требования к качеству</h6>
                  Файлы должны быть предоставлены в одном из 5и размеров:
                  <br />
                  - больше 72 ppi <br />
                  - больше 150 ppi
                  <br />
                  - больше 300 ppi
                  <br />
                  - больше 600 ppi <br />
                  - 1200 ppi
                  <br />
                  (ppi – pixel per inch – точек на дюйм)
                  <br />
                  <br />
                  <h6>Формат превью:</h6>
                  - JPG. мин. Ширина: 800px. Макс. ширина: 1200px
                  <br />
                  <br />
                </div>
              )}
              {nftData.category === "Видео" && (
                <div id="format-video" className="format-info">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - MP4
                  <br />
                  - MOV
                  <br />
                  - MKV
                  <br />
                  - WMV
                  <br />
                  <br />
                  <h6>Требования к качеству</h6>
                  Файлы должны быть предоставлены в одном из 5и размеров:
                  <br />
                  - 480p (SD)
                  <br />
                  - 720p (HD)
                  <br />
                  - 1080p (FHD)
                  <br />
                  - 1440p (3k)
                  <br />
                  - 2160p (4k)
                  <br />
                  <br />
                  <br />
                  <h6>Формат превью:</h6>
                  - MP4 - 480p (SD) макс. Длительность 10с.
                  <br />
                  - GIF покадровая нарезка – длительность 5c. <br />
                  <br />
                </div>
              )}
              {nftData.category === "Векторное изображение" && (
                <div id="format-vector" className="format-info">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - SVG
                  <br />
                  - AI
                  <br />
                  - CDR
                  <br />
                  <br />
                  <h6>Формат превью:</h6>
                  JPG/PNG. мин. Ширина: 800px. Макс. ширина: 1200px.
                  <br />
                  <br />
                </div>
              )}
              {nftData.category === "3D Модель" && (
                <div id="format-model" className="format-info">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - STL
                  <br />
                  - OBJ
                  <br />
                  - FBX
                  <br />
                  - 3DS
                  <br />
                  - IGES
                  <br />
                  - STEP
                  <br />
                  - VRML/X3D
                  <br />
                  - COLLADA
                  <br />
                  <br />
                  <h6>Формат превью:</h6>
                  - JPG
                  <br />
                  - PNG
                  <br />
                  - GIF
                  <br />
                  - MP4 - 480p (SD) макс. Длительность 10с.
                  <br />
                </div>
              )}
              {nftData.category === "Панорамное изображение" && (
                <div id="format-panoramaimg" className="format-info">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - JPG
                  <br />
                  - PNG
                  <br />
                  <br />
                  <h6>Требования к качеству</h6>
                  Файлы должны быть предоставлены в одном из 5и размеров:
                  <br />
                  - 8000х4000px
                  <br />
                  - 16000х8000px
                  <br />
                  - 27000х13500px
                  <br />
                  - 45000х22500px
                  <br />
                  <br />
                  <br />
                  <h6>Формат превью:</h6>
                  - JPG
                  <br />
                  - MP4 - 480p (SD) макс. Длительность 10с.
                  <br />
                  - GIF покадровая нарезка – длительность 5c. <br />
                </div>
              )}
              {nftData.category === "Панорамное видео" && (
                <div id="format-panoramavid" className="format-info">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - MP4
                  <br />
                  - MKV
                  <br />
                  - AVI
                  <br />
                  - MOV
                  <br />
                  <br />
                  <h6>Требования к качеству</h6>
                  Файлы должны быть предоставлены в одном из 5и размеров:
                  <br />
                  - Мин разрешение: 1920х960px
                  <br />
                  - Макс разрешение: 8000х4000px
                  <br />
                  <br />
                  <br />
                  <h6>Формат превью:</h6>
                  - JPG. мин. Ширина: 800px. Макс. ширина: 1200px
                  <br />
                  <br />
                </div>
              )}
              {nftData.category === "3D Видео" && (
                <div id="format-3dvideo" className="format-info">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - MP4
                  <br />
                  - MKV
                  <br />
                  - AVI
                  <br />
                  - MOV
                  <br />
                  <br />
                  <h6>Требования к качеству</h6>
                  Файлы должны быть предоставлены в одном из 5и размеров:
                  <br />
                  - 480p (SD)
                  <br />
                  - 720p (HD)
                  <br />
                  - 1080p (FHD)
                  <br />
                  - 1440p (3k)
                  <br />
                  - 2160p (4k)
                  <br />
                  <br />
                  <h6>Формат превью:</h6>
                  - MP4 - 480p (SD) макс. Длительность 10с.
                  <br />
                  - GIF покадровая нарезка – длительность 5c. <br />
                </div>
              )}
              {nftData.category === "Интерактивный контент" && (
                <div id="format-inter" className="format-info">
                  <hr />
                  <h5>
                    Файлы должны быть предоставлены в одном из следующих
                    форматов:
                  </h5>
                  - ZIP
                  <br />
                  - RAR
                  <br />
                  - APK
                  <br />
                  - DMG
                  <br />
                  - ISO <br />
                  <br />
                  <h6>Формат превью:</h6>
                  - JPG
                  <br />
                  - PNG
                  <br />
                  - GIF
                  <br />
                  - MP4 - 480p (SD) макс. Длительность 10с.
                  <br />
                  - GIF покадровая нарезка – длительность 5c
                  <br />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <Loader visible={loader} description={`NFT Создаётся`} />
    </>
  );
});

export default CreateNFT;

{
  /* <ModalForm title={'Создание NFT'} onSubmit={onSubmit} button={'Создать'}>

            <TextInput title={'Название'} name={'title'} onChange={onChange} hidden={false} />
            <TextInput title={'Описание'} name={'about'} onChange={onChange} hidden={false} />
            <TextInput title={'Цена'} name={'price'} onChange={onChange} hidden={false} />
            <TextInput title={'Категория'} name={'category'} onChange={onChange} hidden={false} />
            <div >
                <label  htmlFor="avatar-upload">Загрузить файл</label>
                <input id='avatar-upload'  style={{display:'none'}} type={'file'} onChange={fileUpload}></input>
            </div>

        </ModalForm> */
}
