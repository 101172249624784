import { Provider } from "inversify-react";
import { Router, Route, Switch } from "react-router-dom";
import PrivatePages from "./components/auth/auth"; //user auth
import Admin from "./pages/admin";
import { RootStore } from "./stores/RootStore";
import Auth from "./pages/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const rootStore = new RootStore();
  const container = rootStore.container;

  return (
    <Router history={rootStore.historyStore as any}>
      <Provider container={container}>
        <PrivatePages>
          <Switch>
            <Route exact path={"/"} component={Auth} />
            <Route path="/admin" component={Admin} />
          </Switch>
        </PrivatePages>
        <ToastContainer />
      </Provider>
    </Router>
  );
};

export default App;
