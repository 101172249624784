import React, { useState } from "react";
import { toast } from "react-toastify";
import { languages } from "../../../../utils/constants";
import styled from "styled-components";
import { SubmitButton } from "../../users/createUserModal";
import InputForm from "./inputForm";
import { IForm, INews } from "../types";

const getDefaultInitialValues = (): INews => {
  let result = {
    news: null,
    translations: [],
  };

  languages.forEach((language) => {
    result.translations.push({
      lang: language,
      title: "",
      description: "",
      metaTite: "",
      metaDescription: "",
    });
  });

  return result;
};

export const getLanguageIndex = (
  values: INews,
  lang: string = "ru"
): number => {
  return values.translations?.map((item) => item.lang).indexOf(lang);
};

const getInputValue = (
  totalInfo: INews,
  keyName: string,
  lang: string = "ru"
): string => {
  const index = getLanguageIndex(totalInfo, lang);
  return totalInfo.translations[index][keyName];
};

const FormCreateUpdate: React.FC<IForm> = ({
  handleSubmit,
  initialValues = getDefaultInitialValues(),
  fileRequired = true,
  isCreate = true,
}) => {
  const [postData, setPostData] = useState<INews>(initialValues);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [inputName, lang]: string[] = e.target.name.split("-");
    const newPostData = { ...postData };
    const index = getLanguageIndex(newPostData, lang);
    newPostData.translations[index][inputName] = e.target.value;

    setPostData(newPostData);
  };

  const previewUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPostData({ ...postData, news: e.target.files[0] });
    toast.success("Файл загружен");
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(postData);
  };
  return (
    <form onSubmit={onSubmit}>
      <FormWrapper>
        {postData.translations.map((item) => {
          return (
            <FormItemWrapper>
              <InputForm
                title={`Заголовок (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(postData, "title", item.lang)}
                name={`title-${item.lang}`}
                required={true}
              />

              <InputForm
                title={`Краткое описание (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(postData, "description", item.lang)}
                name={`description-${item.lang}`}
                required={true}
              />

              <InputForm
                title={`Meta title (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(postData, "metaTite", item.lang)}
                name={`metaTite-${item.lang}`}
                required={true}
              />

              <InputForm
                title={`Meta description (${item.lang.toUpperCase()})`}
                handleChange={onChange}
                inputValue={getInputValue(
                  postData,
                  "metaDescription",
                  item.lang
                )}
                name={`metaDescription-${item.lang}`}
                required={true}
              />
            </FormItemWrapper>
          );
        })}
      </FormWrapper>

      <div
        className="lk-title"
        style={{ marginBottom: "20px", marginTop: "15px" }}
      >
        Выберите превью новости
      </div>
      {!postData.news ? (
        <span>
          <span className="fake-btn">Выберите файл</span>
          <span className="file-msg">Или перетащите его сюда</span>
          <input
            required={fileRequired}
            onChange={previewUpload}
            className="file-input"
            style={{
              height: "17%",
              position: "relative",
              transform: "translateY(-27px)",
            }}
            type="file"
            multiple
          />
        </span>
      ) : (
        <>
          <span className="fake-btn">Выбрать другой файл</span>
          <span className="file-msg">Или перетащите его сюда</span>
          <input
            onChange={previewUpload}
            className="file-input"
            style={{
              height: "17%",
              position: "relative",
              transform: "translateY(-27px)",
            }}
            type="file"
            multiple
          />
        </>
      )}

      <div style={{ width: "50%" }}>
        <SubmitButton type="submit" className="frm_button">
          {`${isCreate ? "Создать" : "Редактировать"} новость`}
        </SubmitButton>
      </div>
    </form>
  );
};

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FormItemWrapper = styled.div`
  width: 49%;
`;

export default FormCreateUpdate;
