import { useInjection } from "inversify-react"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"
import { UserStore } from "../../../stores/user/UserStore"
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import CreateUser from "./createUserModal";

const Flex = styled.div`
  width:  90vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  
`



const Request = observer(() => {

    const store = useInjection(UserStore)
    const [userId, setUserID] = useState(null)
    const [newUserModal, setNewUserModal] = useState(false)
    const history = useHistory();
    
    useEffect(() => {
        store.getRequests();

    }, [])

    const onChange = (e,user) => {
        switch(e.target.value){
            case 'onApprove': return onApprove(user);
            case 'onDecline': return onDecline(user);
            default: ''
        }
    }

    const onApprove = (user) => {
        store.approveRequest(user._id)
    }

    const onDecline = (user) => {
        store.declineRequest(user._id)
    }


    return (
        <div>
            <Flex>
                <h5>Список заявок на художника</h5>
                {/* <Button onClick={()=>setNewUserModal(true)}>Создать пользователя</Button> */}
            </Flex>
            <br />
            <br />

<table className="table">
  <thead>
    <tr>
      <th scope="col">@id</th>
      <th scope="col">ФИО</th>
      <th scope="col">email</th>
      <th scope="col">Тип</th>

      <th scope="col">Опции</th>

    </tr>
  </thead>
  <tbody>
      {store?.requests ? store.requests.map(user => {
          return (
            <tr 
                onMouseOver={() =>setUserID(user._id)}
                onMouseEnter={() => setUserID(user._id)}
                style={{backgroundColor: userId==user._id && '#ebebeb'}}
                onDoubleClick={() => userId==user._id && history.push(`/admin/users/${userId}`)} >
            <th scope="row">{user.user_id}</th>
            <td>{user.fullname}</td>
            <td>{user.email}</td>
            <td>{user.user_type}</td>

            <td>
                <select onChange={e=>onChange(e,user)}>
                    <option>Опции</option>
                    <option value='onApprove'>Подтвердить</option>
                    <option value='onDecline'>Отказать</option>

                </select>
            </td>
          </tr>
          )
      }) : (
          <p>loading...</p>
      )}
    
    </tbody>
  </table>
    
    {newUserModal && <CreateUser close={()=>setNewUserModal(false)} />}

</div>
    )
})

export default Request