import { useInjection } from "inversify-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import {useLocation, useParams} from "react-router"
import { UserStore } from "../../../stores/user/UserStore";
import {toast} from "react-toastify";
import style from '../admin.module.scss'



const OneUser = observer(() => {
    const params: any = useParams();
    const location = useLocation()
    const store = useInjection(UserStore)
    const [isEdit, setIsEdit] = useState(true)
    const [active, setActive] = useState(false)
    const [formData, setFormData] = useState(null)
    let id = params.id

    useEffect(() => {

        store.GetUser(id).then(res => {
            setActive(true)
        })

        return () => setActive(false)
    }, [location])


    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const onSubmit = (e) => {
        e.preventDefault()
        console.log(formData, 'form data')
        store.ChangeUser(formData, id).then(res => {
            toast.success('Изменение данных')
        })
        setIsEdit(true)

    }


    const onDelete = () => {
        if (window.confirm(`Вы уверены что хотите удалить пользователя ${store.user.fullname}?`)) {
            store.deleteUser(id)
        }


    }

    const onOption = (e,user) => {
        switch(e.target.value){
            case 'onApprove': return onApprove(user);
            case 'onDecline': return onDecline(user);
            default: ''
        }
    }

    const onApprove = (user) => {
        store.approveRequest(user._id)
        store.GetUser(id).then(res => {
            setActive(true)
        })
    }

    const onDecline = (user) => {
        store.declineRequest(user._id)
        store.GetUser(id).then(res => {
            setActive(true)
        })
    }

    return (
        <div>
            {active ?
    (
        <div>
            <form onSubmit={onSubmit}>


                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input   type="text" onChange={onChange} name={'email'}  readOnly={isEdit} defaultValue={store.user?.email}  className="form-control" id="exampleInputEmail1"  aria-describedby="emailHelp" placeholder="Enter email" />
                </div>


                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Тип пользователя</label>
                    <input type="text" onChange={onChange} defaultValue={store.user?.user_type} readOnly={isEdit} className="form-control" id="exampleInputEmail1"  />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">ФИО</label>
                    <input type="text" onChange={onChange} name={'fullname'} defaultValue={store.user?.fullname} readOnly={isEdit} className="form-control" id="exampleInputEmail1"  />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Баланс BNB</label>
                    <input type="text" onChange={onChange} name={'balance'} defaultValue={store.user?.balance} readOnly={true} className="form-control" id="exampleInputEmail1"  />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">user id</label>
                    <input type="text" onChange={onChange} name={'user_id'} defaultValue={store.user?.user_id} readOnly={isEdit} className="form-control" id="exampleInputEmail1"  />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">user about</label>
                    <textarea  onChange={onChange} name={'about'} defaultValue={store.user?.about} readOnly={isEdit} className="form-control" id="exampleInputEmail1"  />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Образование</label>
                    <textarea  onChange={onChange} name={'education'} defaultValue={store.user?.education} readOnly={isEdit} className="form-control" id="exampleInputEmail1"  />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Ссылки</label>
                    <input type="text" onChange={onChange} name={'social'} defaultValue={store.user?.social} readOnly={isEdit} className="form-control" id="exampleInputEmail1"  />
                </div>

                    <br />

                <button type="submit" className="btn btn-primary">Подтвердить</button>

                {store.user?.type_pending && <div style={{color:'red', margin: '20px 0 0'}}>
                    Заявка на художника:
                    <select onChange={e=>onOption(e,store.user)}>
                        <option>Опции</option>
                        <option value='onApprove'>Подтвердить</option>
                        <option value='onDecline'>Отказать</option>
                    </select>
                </div>}
            </form>
            <br />
            {isEdit && (
                <>
                    <button className="btn btn-primary"  onClick={onDelete}>Удалить пользователя</button>
                    <button className="btn btn-primary" style={{marginLeft: "10px"}} onClick={() =>setIsEdit(!isEdit)}>Редактировать пользователя</button>

                </>
            )}




        </div>
    ) : (
                    <div>loading</div>
                )}
        </div>

)})


export default OneUser