import { IReview } from "./../../pages/admin/pages/review/index";
import { INews } from "./../../pages/admin/news/types";
import axios from "axios";
import { injectable } from "inversify";
import { makeObservable, observable } from "mobx";
import "reflect-metadata";
import { innerBackend, setAuthToken, url } from "../../components/auth/helper";
import { LoginData } from "../../interfaces/auth";
import { RootStore } from "../RootStore";
import { toast } from "react-toastify";
import { IPage, IPages } from "./../../pages/admin/pages/types";
import { ICategory } from "./../..//pages/admin/categories/types";
import { IChapter } from "./../../pages/admin/chapters";
import { ISetting } from "./../../pages/admin/search";

@injectable()
export class UserStore {
  @observable user?: any;
  @observable users: any;
  @observable requests: any;
  @observable nft: any;
  @observable post: any;
  @observable allNFT: any;
  @observable allNews: INews[] | null;
  @observable isAuth: boolean;
  @observable isError: boolean;
  @observable tags: any;
  @observable allPages: IPage[] | null;
  @observable page: any;
  @observable categories: ICategory[] | null;
  @observable chapters: IChapter[] | null;
  @observable searchSettings: ISetting[] | null;
  @observable reviews: null | IReview[];

  public constructor(private readonly rootStore: RootStore) {
    makeObservable(this);
    this.isAuth = false;
    this.isError = false;
  }

  //@POST /users/auth {email:email,password:password} - авторизация
  ///admin/auth {password,login} admin
  async Login(formData: LoginData) {
    try {
      const res = await axios.post(`${url}/admin/auth`, formData);
      const token = res.data.token;
      localStorage.setItem("token-admin", token);
      setAuthToken(token);
      this.isAuth = true;
    } catch (err) {
      this.isAuth = false;
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  ///admin/validation
  async Validation(token) {
    try {
      setAuthToken(token);
      console.log(token, "token finel");
      const res = await innerBackend.get(`${url}/admin/validation`);
      console.log(res, "res here haha");
      this.isAuth = true;
      return true;
    } catch (err) {
      localStorage.removeItem("token-admin");
      this.isAuth = false;
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  ///admin/findall
  async GetUsers() {
    try {
      const res = await innerBackend.post(`${url}/admin/findall`);
      console.log(res.data, "data");
      this.users = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  ///admin/adduser
  async AddUser(formData) {
    try {
      const res = await innerBackend.post(`${url}/admin/adduser`, formData);
      this.users = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async AddPost(formData: FormData) {
    try {
      const res = await innerBackend.post(`${url}/admin/news/new`, formData);
      // this.users = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async DeletePost(id: string) {
    try {
      await innerBackend.delete(`${url}/admin/news/${id}`);
      this.GetAllNews();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async EditPost(formData: FormData, id: string) {
    console.log(formData, id);
    try {
      const res = await innerBackend.put(
        `${url}/admin/news/edit/${id}`,
        formData
      );
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async GetPost(id: string) {
    this.post = null;
    try {
      const res = await innerBackend.get(`${url}/admin/news/get/${id}`);
      // this.users = res.data;
      this.post = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  ///admin/changeuser/:user_id
  async ChangeUser(formData, id) {
    try {
      console.log(id);
      const res = await innerBackend.put(
        `${url}/admin/changeuser/${id}`,
        formData
      );
      console.log(res.data, "res");
      this.user = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async UploadFile(formData) {
    try {
      const res = await innerBackend.post(
        `${url}/admin/news/loadfile`,
        formData
      );
      return `${url}/${res.data.file}`;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  ///admin/addnft/:user_id   добавление НФТ

  async AddNFT(formData) {
    console.log("AddNFT 1");

    try {
      const res = await innerBackend.post(`${url}/nft/upload`, formData);
      this.allNFT = res.data;
      console.log("AddNFT res", res);
    } catch (err) {
      console.log("AddNFT err", err.response);

      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async UpdateNFT(formData, id: string) {
    try {
      const res = await innerBackend.post(`${url}/nft/update/${id}`, formData);

      toast.success("NFT отредактирован");
      this.GetAllNFT();
    } catch (err) {
      console.log("AddNFT err", err.response);

      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  ///admin/user/:id

  async GetUser(id) {
    try {
      const res = await innerBackend.get(`${url}/admin/user/${id}`);
      this.user = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  ///admin/nft/:id
  async GetNFT(id) {
    try {
      const res = await innerBackend.get(`${url}/admin/nft/${id}`);
      this.nft = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  ///admin/allnfts
  async GetAllNFT() {
    try {
      console.log("nft here");
      const res = await innerBackend.get(`${url}/admin/allnfts`);
      console.log(res.data, "nft");
      this.allNFT = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async GetAllNews() {
    try {
      const res = await innerBackend.get(`${url}/admin/news/all`);
      console.log(res.data, "news");
      this.allNews = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  //DELETE /admin/deletenft/:id

  async deleteNFT(id) {
    try {
      console.log(id, "nft id");
      const res = await innerBackend.delete(`${url}/admin/deletenft/${id}`);
      console.log(res, "res nft del");
      this.allNFT = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  //DELETE /admin/deleteuser/:id

  async deleteUser(id) {
    try {
      const res = await innerBackend.delete(`${url}/admin/deleteuser/${id}`);
      this.users = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async getRequests() {
    try {
      console.log("1");
      const res = await innerBackend.get(`${url}/admin/user/get/pending`);
      console.log(res.data, "res");
      this.requests = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async approveRequest(user_id) {
    try {
      console.log("1");
      const res = await innerBackend.post(
        `${url}/admin/user/type/approve/${user_id}`
      );
      console.log(res.data, "res");
      this.requests = res.data;
    } catch (err) {
      console.log(err.response, "response err");

      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async declineRequest(user_id) {
    try {
      console.log("1");
      const res = await innerBackend.post(
        `${url}/admin/user/type/decline/${user_id}`
      );
      console.log(res.data, "res res");
      this.requests = res.data;
    } catch (err) {
      console.log(err, "response err");

      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async getAllTags() {
    try {
      const res = await innerBackend.get(`${url}/admin/tag/get`);
      console.log(res.data, "data");
      this.tags = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async addNewTag(tag) {
    try {
      const res = await innerBackend.post(`${url}/admin/tag/new/${tag}`);
      this.getAllTags();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async removeTag(tag) {
    try {
      const res = await innerBackend.post(`${url}/admin/tag/delete/${tag}`);
      this.getAllTags();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  //pages
  async GetAllPages() {
    try {
      const res = await innerBackend.get(`${url}/page`);
      this.allPages = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async EditPages(formData: IPages) {
    try {
      const res = await innerBackend.put(`${url}/page`, formData);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async AddPage(formData: IPage) {
    try {
      const res = await innerBackend.post(`${url}/page`, formData);
      // this.users = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async DeletePage(pageUrl: string) {
    try {
      const res = await innerBackend.delete(`${url}/page/${pageUrl}`);
      this.GetAllPages();
      // this.users = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async EditPage(formData: IPage, pageUrl: string) {
    try {
      const res = await innerBackend.put(`${url}/page/${pageUrl}`, formData);
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async GetPage(pageUrl: string) {
    this.page = null;
    try {
      const res = await innerBackend.get(`${url}/page/${pageUrl}`);
      // this.users = res.data;
      this.page = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  //categories
  async getAllCategories() {
    try {
      const res = await innerBackend.get(`${url}/nft/category`);
      this.categories = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async addNewCategory(formData: ICategory) {
    try {
      await innerBackend.post(`${url}/nft/category`, formData);
      this.getAllCategories();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async removCategory(id: string) {
    try {
      await innerBackend.delete(`${url}/nft/category/${id}`);
      this.getAllCategories();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  //chapters
  async getAllChapter() {
    try {
      const res = await innerBackend.get(`${url}/section`);
      this.chapters = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async addNewChapter(formData) {
    try {
      await innerBackend.post(`${url}/section`, formData);
      this.getAllChapter();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async removChapter(id: string) {
    try {
      await innerBackend.delete(`${url}/section/${id}`);
      this.getAllChapter();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async editChapters(formData) {
    try {
      await innerBackend.put(`${url}/section`, formData);
      this.getAllChapter();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  //search settings
  async getAllSearchSettings() {
    try {
      const res = await innerBackend.get(`${url}/setting/search`);
      this.searchSettings = res.data.search;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async addNewSearchSetting(formData) {
    try {
      await innerBackend.post(`${url}/setting/search`, formData);
      this.getAllSearchSettings();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async removeSetting(id: string) {
    try {
      await innerBackend.delete(`${url}/setting/search/${id}`);
      this.getAllSearchSettings();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  async editSettings(formData) {
    try {
      await innerBackend.put(`${url}/setting/search`, formData);
      this.getAllSearchSettings();
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
  //reviews
  async getAllReviews(params) {
    try {
      const res = await innerBackend.get(`${url}/review/all`, { params });
      this.reviews = res.data;
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async approweReview(id, params) {
    try {
      await innerBackend.put(`${url}/review/${id}`, params);
      toast.success("Отзыв успешно одобрен");
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }

  async deleteReview(id) {
    try {
      await innerBackend.delete(`${url}/review/${id}`);
      toast.success("Отзыв успешно удалён");
    } catch (err) {
      let msg = err.response.data.err;
      err?.message && toast.error(msg);
    }
  }
}
